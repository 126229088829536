export default {
  name: 'Account',
  title: 'Account - Localites',

  data() {
    return {
      items: [
        {
          title: 'Personal information',
          description: 'Update your personal info like name, mobile, location and more.',
          slug: 'personal-information'
        },
        {
          title: 'Photos',
          description: 'Upload or modify your photos to create an impressive profile.',
          slug: 'photos'
        },
        {
          title: 'Availability & rate',
          description: 'Update your localite availability, rate, status and more.',
          slug: 'availability-and-rate'
        },
        {
          title: 'Languages',
          description: 'Let others know what languages you know and speak.',
          slug: 'languages'
        },
        {
          title: 'About you',
          description: 'Update your story, unique things about your city and more.',
          slug: 'about-you'
        },
        {
          title: 'Hosting & pick-up',
          description: 'Update if you can host and pick-up people and charges if any.',
          slug: 'hosting-and-pickup'
        },
        {
          title: 'Payments & payouts',
          description: 'Update or manage your payment and payout details.',
          slug: 'payments-and-payouts'
        },
        {
          title: 'Account verification',
          description: 'Submit a request to verify your community account.',
          slug: 'account-verification'
        }/*,
        {
          title: 'Settings & subscription',
          description: 'Manage settings, subscription and account status.',
          slug: 'settings-and-subscription'
        }*/
      ]
    }
  }
}